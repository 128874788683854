<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      width="800px"
      :mask-closable="false"
      title="批量上传文档"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <a-form
        class="sfm-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="文件" required>
          <div>支持DOC/DOCX/PPT/PDF/XLS/XLSX/TXT格式文件，大小不超过20GB</div>
            <a-upload
              name="file"
              accept=".doc, .docx, .ppt, .pptx, .pdf, .xls, .xlsx, .txt"
              :file-list="fileList"
              :before-upload="handleBeforeUploadDocument"
              :custom-request="handleUploadDocument"
              :remove="handleRemoveDocument"
              :multiple="true"
              v-decorator="['document',{
                rules:[
                  {validator: checkDocument}
                ]
              }]"
            >
              <a-icon type="loading" v-if="documentloading" />
              <a>
                点击上传
              </a>
            </a-upload>
        </a-form-item>

        <a-form-item label="文档授权">
          <a-radio-group
            v-decorator="['is_free',{
              initialValue: false,
              rules: [
                { required: true, message: '请选择文档授权' },
              ]
            }]"
            @change="handleChangeIsFree"
          >
            <a-radio :value="false">
              收费
            </a-radio>
            <a-radio :value="true">
              免费
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="价格(积分)" v-if="!isFree">
          <a-input-number
            :min="1"
            :max="10000000"
            :precision="0"
            :step="1"
            style="width: 100%"
            v-decorator="['point', {
              rules: [
                { required: true, message: '请输入价格(积分)' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="推荐">
          <a-checkbox
            v-decorator="['is_recommend',{
              valuePropName: 'checked',
              initialValue: false
            }]"
          />
        </a-form-item>

        <a-form-item label="显示">
          <a-checkbox
            v-decorator="['visible',{
              valuePropName: 'checked',
              initialValue: true
            }]"
          />
        </a-form-item>

        <a-form-item label="一级分类">
          <a-select
            v-decorator="['first_category_id']"
            placeholder="请选择一级分类"
            showSearch
            allow-clear
            :filter-option="filterOption"
            :loading="loadingFirstCateOptions"
            @focus="fetchFirstCateOptions"
            @change="handleFirstCateChange"
          >
            <a-select-option
              v-for="item in firstCateOptions"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="二级分类">
          <a-select
            v-decorator="['second_category_id']"
            placeholder="请选择二级分类"
            allow-clear
            showSearch
            :filter-option="filterOption"
            :loading="loadingSecondCateOptions"
            @change="handleSecondCateChange"
          >
            <a-select-option
              v-for="item in secondCateOptions"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

         <a-form-item label="三级分类">
          <a-select
            v-decorator="['third_category_id']"
            placeholder="请选择三级分类"
            showSearch
            :filter-option="filterOption"
            allow-clear
            :loading="loadingThirdCateOptions"
          >
            <a-select-option
              v-for="item in thirdCateOptions"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="标签">
          <a-textarea
            v-decorator="['label']"
            placeholder="请为文档打上标签，每个标签不超过5个字，标签之间用顿号隔开，便签数量不超过10个"
            autocomplete="off"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { DocumentCategoryModel } from "@/models/document_category.js"
const documentCategoryModel = new DocumentCategoryModel()
import { DocumentModel } from "@/models/document.js"
const documentModel = new DocumentModel()
import upload from "@/utils/oss";
import { FileChecksum } from "@/utils/fileChecksum";

export default {
  name: 'BatchCreateDocumentModal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    rootId: {
      type: Number,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'batch_new_document' }),
      submitting: false,
      documentloading: false,
      isFree: false,
      firstCateOptions: [],
      secondCateOptions: [],
      thirdCateOptions: [],
      loadingFirstCateOptions: false,
      loadingSecondCateOptions: false,
      loadingThirdCateOptions: false,
      searchingRootId: 0,
      maxDocumentSize: 20 * 1024 * 1024 * 1024,
      fileList: [],
      submitFileList: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleBeforeUploadDocument(file) {
      if (file.size > this.maxDocumentSize) {
        this.$notification.error({
          message: '提示',
          description: `文件大小不能超过 ${this.maxDocumentSize / 1024.0 / 1024.0 / 1024} GB`,
          duration: 2
        })
        file.status = 'error'
        return false
      }

      // 文件类型是否合法
      const fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (!['doc', 'docx', 'ppt', 'pptx', 'pdf', 'xls', 'xlsx', 'txt'].includes(fileType)) {
        this.$notification.error({
          message: '提示',
          description: `文件格式不正确`,
          duration: 2
        })
        file.status = 'error'
        return false
      }
    },

    handleUploadDocument(options) {
      const { file } = options
      this.documentloading = true
      this.fileList.push(file)
      const fileChecksum = new FileChecksum(file)
      fileChecksum.create((error, checksum) => {
        upload(file, "softmall-documents", {}).then((key) => {
          if (key) {
            this.fileList[this.fileList.findIndex(item => item.uid === file.uid)].key = key
            this.submitFileList.push({
              key: key,
              filename: file.name,
              byte_size: file.size,
              content_type: file.type,
              checksum: checksum,
            })
            this.documentloading = false
          } else {
            this.fileList.splice(this.fileList.findIndex(item => item.uid === file.uid), 1)
          }
        })
      })
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleRemoveDocument(file) {
      this.fileList.splice(this.fileList.findIndex(item => item.uid === file.uid), 1)
      this.submitFileList.splice(this.submitFileList.findIndex(item => item.key === file.key), 1)
    },

    fetchFirstCateOptions() {
      if (this.firstCateOptions.length === 0) {
        this.loadingFirstCateOptions = true
        const data = {
          root_id: 0,
          parent_id: 0
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.firstCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingFirstCateOptions = false
        })
      }
    },

    // 检查是否上传文档
    checkDocument(rule, value, callback) {
      if (this.fileList.length === 0 || this.fileList[0].status === 'error') {
        callback('请上传文件')
        return
      }

      callback()
    },

    handleFirstCateChange(firstCateId) {
      this.secondCateOptions = []
      this.thirdCateOptions = []
      this.searchingRootId = 0

      this.form.setFieldsValue({
        second_category_id: undefined,
        third_category_id: undefined
      })

      if (firstCateId) {
        this.searchingRootId = firstCateId
        this.fetchSecondCateOptions({ root_id: firstCateId, parent_id: firstCateId })
      }
    },

    handleSecondCateChange(secondCateId) {
      this.thirdCateOptions = []

      this.form.setFieldsValue({
        third_category_id: undefined
      })

      if (secondCateId) {
        this.fetchThirdCateOptions({ root_id: this.searchingRootId, parent_id: secondCateId })
      }
    },

    fetchSecondCateOptions(params) {
      if (this.secondCateOptions.length === 0) {
        this.loadingSecondCateOptions = true
        const data = {
          root_id: params.root_id,
          parent_id: params.parent_id
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.secondCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingSecondCateOptions = false
        })
      }
    },

    fetchThirdCateOptions(params) {
      if (this.thirdCateOptions.length === 0) {
        this.loadingThirdCateOptions = true
        const data = {
          root_id: params.root_id,
          parent_id: params.parent_id
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.thirdCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingThirdCateOptions = false
        })
      }
    },

    handleChangeIsFree(e) {
      this.isFree = e.target.value
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const params = {
            ...values,
            file_list: this.submitFileList
          }
          delete params.document
          documentModel.batch_create(params).then((res) => {
            if (res.code === 0) {
              this.$notification.open({
                message: '提示',
                description: res.message,
                duration: 2
              })
              this.isShow = false
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 5px;
}

.icon {
  width: 86px;
}
</style>
